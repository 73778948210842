<template>
  <div>
    <div class="simp-drag-captcha">
      <span class="drag-text">{{dragText}}</span>
      <div class="dragged-path" :style="{width: dragLength + 'px'}"></div>
      <div class="drag-bar" @mousedown="unLocked || mousedownHandle($event)" @mouseenter="computeMaxLength($event)">➡
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'simpDragCaptcha',
  data () {
    return {
      unLocked: false,
      dragLength: 0,
      maxLength: 0,
      $dragBar: null,
      $draggedPath: null,
      precisionWidth: 0,
      dragText: '滑动解锁'
    }
  },
  methods: {
    computeMaxLength (e) {
      this.$dragBar = $(e.target)
      this.$draggedPath = this.$dragBar.prev()
      this.maxLength = this.$dragBar.parent().outerWidth(true) - this.$dragBar.outerWidth(true) - 2
      this.$dragBar.unbind('mouseenter', this.computeMaxLength)
    },
    dragHandle (e) {
      const length = e.pageX - this.$draggedPath.offset().left - this.precisionWidth
      if (length < 0) {
        this.dragLength = 0
      } else if (length > this.maxLength) {
        this.dragLength = this.maxLength
      } else {
        this.dragLength = length
      }
    },
    mousedownHandle (e) {
      this.precisionWidth = e.pageX - this.$dragBar.offset().left
      this.$draggedPath.css({
        border: '1px solid #1991FA',
        'background-color': '#D1E9FE'
      })
      $(document).mousemove(this.dragHandle)
    }
  },
  created () {
    $(document).mouseup((e) => {
      $(document).unbind('mousemove', this.dragHandle)
      if (!this.$draggedPath) {
        return
      }
      if (this.dragLength < this.maxLength / 2) {
        this.$draggedPath.animate({
          border: '1px solid #f57a7a',
          'background-color': '#fce1e1'
        }, 'fast')
        this.$draggedPath.animate({
          border: 0,
          width: 0
        }, 'fast')
      } else {
        this.$draggedPath.animate({
          width: this.maxLength
        }, 'fast')
        this.dragText = '已解锁'
        this.unLocked = true
        this.$emit('unloacked')
      }
    })
  }
}
</script>

<style scoped>
  /*@formatter:off*/
    .simp-drag-captcha {
        width: 100%;
        height: 35px;
        border: 1px solid #ccc;
        line-height: 34px;
    }

    .simp-drag-captcha > * {
        height: 100%;
        float: left;
    }

    .drag-text {
        position: absolute;
        text-align: center;
        transform: translateX(-50%);
        margin-left: 50%;
        color: #6f6c6c;
    }

    .dragged-path {
        transition: background-color 0.5s, border 0.5s;
    }

    .drag-bar {
        padding: 0 10px;
        color: #676d73;
        box-shadow: 0 0 1px 1px #ccc;
        transition: background-color 0.5s;
        cursor: pointer;
    }

    .drag-bar:hover {
        background-color: #1991FA;
        color: #FFFFFF;
    }

    /*@formatter:on*/
</style>
